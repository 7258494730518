import React, { useRef, useLayoutEffect, useState } from "react";
import Color from "color";

interface Props {
  label: string;
  value: number;
  ratio: number;
  width: string;
  fillColor: string;
  baseSize: string;
  labelSize: string;
  showValue: boolean;
}
export const Bar: React.FC<Props> = ({
  label,
  value,
  ratio,
  width,
  fillColor,
  baseSize,
  labelSize,
  showValue: optShowValue
}) => {
  const barRef = useRef({} as HTMLInputElement);
  const valueRef = useRef({} as HTMLInputElement);
  const [showValue, setShowValue] = useState(true);
  useLayoutEffect(() => {
    const barReact = barRef.current.getBoundingClientRect();
    const valueRect = valueRef.current.getBoundingClientRect();
    setShowValue(barReact.width > valueRect.width);
  }, [value, optShowValue]);
  return (
    <div
      className="datum"
      key={label}
      style={{ fontSize: baseSize, width: "100%" }}
    >
      <div
        className="label"
        style={{
          fontSize: labelSize,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis"
        }}
      >
        {label}
      </div>
      <div className="value">
        <div
          ref={barRef}
          className="bar"
          style={{
            width: width,
            backgroundColor: fillColor
          }}
        >
          <span className="number" ref={valueRef}>
            {optShowValue && showValue && value}
          </span>
        </div>
        <span
          className="ratio"
          style={{
            color: fillColor,
            filter: `drop-shadow(2px 2px 1px ${Color(fillColor).lighten(0.8)})`
          }}
        >
          {ratio}%
        </span>
      </div>
    </div>
  );
};
