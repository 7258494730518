import React, { useState, useEffect } from "react";
import axios from "axios";
import { createConsumer, CableMessage } from "@rails/actioncable";
import { Chart, Datum } from "./Chart";
import { applyTheme, ThemeEnum } from "./Theme";
import "./App.css";

interface QuestionResultDatum {
  choices: Array<string>;
  bothLanguageChoices: Array<string>;
  results: Array<number>;
  results_rate: Array<number>;
}

interface Prop {
  token: string;
  questionId: string;
  apiHost: string;
  conferenceId: string;
  actionCable: string;
  theme: ThemeEnum;
}

const App: React.FC<Prop> = ({ token, questionId, apiHost, conferenceId, actionCable, theme }) => {
  const [data, setData] = useState<Array<Datum>>([]);
  const [showValue, setShowValue] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get<QuestionResultDatum>(`${apiHost}/api/questions/${questionId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const result_data = result.data;
      const tmpData = result_data.bothLanguageChoices.map((choice, index) => ({
        label: choice,
        value: result_data.results[index],
        ratio: result_data.results_rate[index]
      }));
      setData(tmpData);
      const timeoutId = setTimeout(() => fetchData(), 5000);
      return () => clearTimeout(timeoutId);
    };
    fetchData();
  }, [token, questionId, apiHost]);

  useEffect(() => {
    const cable = createConsumer(actionCable);
    cable.subscriptions.create(
      {
        channel: "ProjectorChannel",
        conference_id: conferenceId
      },
      {
        connected: () => {},
        disconnected: () => {},
        received: (message: CableMessage) => {
          if (message.action === "change_chart_value_visibility") {
            setShowValue(JSON.parse(message.visibility));
          }
        }
      }
    );
  }, [actionCable, conferenceId]);
  applyTheme(theme);
  return (
    <div className="App">
      <Chart data={data} showValue={showValue} />
    </div>
  );
};

export default App;
