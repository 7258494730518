import React, { useState, useEffect } from "react";
import { Bar } from "./Bar";

const defaultColors = [
  "#6a9ae2",
  "#f25a5a",
  "#1de212",
  "#ba12e2",
  "#12d7e2",
  "#ebeb0a",
  "#eba80a",
  "#e2b5b5",
  "#f7b6f1",
  "#d2d2d2",
  "#7fffd4",
  "#deb887"
];

export interface Datum {
  label: string;
  value: number;
  ratio: number;
}
interface Props {
  data: Array<Datum>;
  showValue: boolean;
}
export const Chart: React.FC<Props> = ({ data, showValue }) => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (data.length < 1) {
    return <React.Fragment />;
  }
  const maxValue = data.reduce((a, b) => (a.value > b.value ? a : b)).value;
  const maxCharacters = data.reduce((a, b) =>
    a.label.length > b.label.length ? a : b
  ).label.length;
  let datumSize = dimensions.height / data.length;
  if (datumSize < 50) {
    datumSize = dimensions.height / Math.ceil(data.length / 2);
  }
  if (datumSize > 150) {
    datumSize = 150;
  }
  let labelSize = 0.4;
  const oneLineCharacters =
    Math.ceil(dimensions.width / (datumSize * labelSize)) - 3;
  if (oneLineCharacters < maxCharacters) {
    labelSize = labelSize * (oneLineCharacters / maxCharacters);
  }
  if (labelSize < 0.3) {
    labelSize = 0.3
  }
  return (
    <div className="chart">
      {data.map((datum, index) => (
        <Bar
          key={`chart-bar-${index}`}
          label={datum.label}
          value={datum.value}
          ratio={datum.ratio}
          width={`calc(${datum.ratio}%)`}
          fillColor={defaultColors[index]}
          baseSize={`${datumSize}px`}
          labelSize={`${labelSize}em`}
          showValue={showValue}
        />
      ))}
    </div>
  );
};
