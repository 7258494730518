// import { ThemeEnum } from "./App";

type ITheme = {
  [key in ThemeEnum]: ThemeObject;
};
interface ThemeObject {
  [key: string]: string;
}
export enum ThemeEnum {
  LIGHT = "theme-bright",
  DARK = "theme-dark"
}

const Theme: ITheme = {
  [ThemeEnum.DARK]: {
    color: "white"
  },
  [ThemeEnum.LIGHT]: {
    color: "black"
  }
};

export function applyTheme(themeName: ThemeEnum) {
  const themeObj = Theme[themeName];
  Object.keys(themeObj).forEach(key => {
    if (themeObj.hasOwnProperty(key)) {
      document.documentElement.style.setProperty(`--${key}`, themeObj[key]);
    }
  });
}
